<template>
  <el-container class="patroladd">

    <el-header class="box add bb">
         <el-breadcrumb separator-class="el-icon-arrow-right">
       <el-breadcrumb-item>权限管理</el-breadcrumb-item>
       <el-breadcrumb-item >角色权限</el-breadcrumb-item>
        <el-breadcrumb-item v-if="form.role_id">编辑角色菜单权限</el-breadcrumb-item>
         <el-breadcrumb-item v-else>新增角色菜单权限</el-breadcrumb-item>
    </el-breadcrumb>

        <div><el-button  class="back_btn" size="medium" plain @click="$router.back()">返回</el-button></div>
    </el-header>
    <el-main class="">
    <el-form  :inline="true"  status-icon ref="form"  :model="form"  class="demo-ruleForm">
      <el-row>
         <el-col :span="11" class="tl" >
           <el-form-item label="角色名称："  class="w" label-width="28%"  prop='role_name'  :rules="[{required: true,message: '请输入角色名称', trigger: 'blur' },{ pattern: /^[A-Za-z0-9\u4e00-\u9fa5]+$/, message: '不允许输入空格等特殊符号' }]">
          <el-input v-model="form.role_name" size="medium"  clearable></el-input>
        </el-form-item>
        </el-col>
      </el-row>
      <el-collapse v-if="form.role_id" v-model="activeNames" @change="handleChange">
       <el-collapse-item v-for="(item,index) in menu_list" :key="index" :title="item.menu_name" :name="index">
        <el-row class="mt20 f14">
           <el-checkbox-group v-model="checkboxGroup1" @change="handleCheckedChange(checkboxGroup1)">
            <div  v-for="(item1,index) in item.menu_list" :key="index">
              <el-col   :span="11" class="clear" >
           <el-checkbox  v-if="item1.menu_level==2" class="ml10 mb20 disib"  :label="item1.menu_id" :value="item1.menu_id" :key="item1.menu_id" border size="medium">{{item1.menu_name}}</el-checkbox>

         </el-col>
         <el-col  :span="22"  v-if="item1.menu_level==2">
             <!-- <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox> -->
              <el-checkbox-group  v-if="item1.menu_button_list" v-model="checkboxGroup3" @change="handleCheckedChange1(item1.menu_id,checkboxGroup3)">
    <p class="f14 ml10 tl mb20 fb">页面按钮权限</p>
    <el-col :span="4"   v-for="(city,index) in item1.menu_button_list" :key="index" class="disib mb20 ml10" >
      <el-checkbox  :label="city.menubtn_id" :value="city.menubtn_id" border size="medium" >{{city.menubtn_title}}</el-checkbox>
    </el-col>

  </el-checkbox-group>
           </el-col>
            </div>
         </el-checkbox-group>
        </el-row>
       </el-collapse-item>
      </el-collapse>
      <div class="tl mt15">
             <el-button  class=""  size="medium" type="primary" @click="submitForm('form')">提交</el-button>
          </div>
     </el-form>
     <!-- <el-form v-else :inline="true"  status-icon ref="form"  :model="form"  class="demo-ruleForm">
       <el-col :span="22" class="tl" >
           <el-form-item label="角色名称："  class="w" label-width="14%"  prop='role_name'  :rules="[{required: true,message: '请输入角色名称', trigger: 'blur' },{ pattern: /^[A-Za-z0-9\u4e00-\u9fa5]+$/, message: '不允许输入空格等特殊符号' }]">
          <el-input v-model="form.role_name" size="medium"  clearable></el-input>
        </el-form-item>
        </el-col>

     </el-form> -->
     </el-main>
  </el-container>
</template>
<script>
import axios from '../../../util/api'
import { local,session } from '../../../util/util'
export default {
  data () {
    return {
      menu_list:'',
      activeNames: ['1'],
      aa:[],
      bb:[],
      cc:[],
        form: {
          company_id:'5',
            role_id:'',
            role_name:'',
            sys_state:'',
          menu_list:[]
      },
       checked1: true,
        checked2: false,
        checkboxGroup1: [],
        checkboxGroup3: [],
        checkboxGroup2:[],
        checkboxGroup4: [],
        list:[],
        list1:[],
        user_id_add:'',
        user_name_add:'',
    }
  },
     created () {
      this.user = local.get('user');
       this.user_id_add=this.user.user_id;
        this.user_name_add=this.user.user_name;
       this.form.role_id=session.get('params').id?String(session.get('params').id):'';
       this.form.sys_state = session.get("params").sys_state?String(session.get('params').sys_state):''
     this.init()
  },
  methods: {
     init (params) {
          axios.get('/pc/menu/all').then((v) => {
        this.menu_list=v.data;
      })
       if(this.form.role_id){
          axios.get('/pc/role/single',{role_id:this.form.role_id}).then((v) => {
           this.form.role_name=v.data.role.role_name
          //  this.form.menu_list=v.data.role.menu_list;
            v.data.role.menu_list.forEach(item=>{
          // if(item.menu_level==2){
           if(item.page_id.includes('-')){
        this.checkboxGroup1.push(item.menu_id)
         }

         this.checkboxGroup4=this.checkboxGroup1;
          item.menu_btns_list.forEach(item1=>{
          this.checkboxGroup3.push(item1)
        })
         this.form.menu_list.push({menu_btns_list:item.menu_btns_list,menu_id:item.menu_id})
    // }
})
      })
       }
    },
    getArrDifference(arr1, arr2) {
        return arr1.concat(arr2).filter(function(v, i, arr) {
            return arr.indexOf(v) === arr.lastIndexOf(v);
        });
    },
    handleCheckedChange(val){
       let aaa;
      this.list=[];
      var len1=val.length
      var len2=this.checkboxGroup4.length;

       this.getArrDifference(val,this.checkboxGroup4).forEach(item=>{
       aaa=item
      })
       this.bb=[];
       this.cc=[];
        this.checkboxGroup2=[];
        this.checkboxGroup3=[];
      // this.form.menu_list= [];
      //
     this.menu_list.forEach(item=>{
         item.menu_list.forEach(item1=>{
          val.forEach(item2=>{
              if(item1.menu_id==item2){
                  this.checkboxGroup2.push(item1.menu_parent_id)
              }
          })
         })

      })
      this.list=this.list.concat(this.checkboxGroup2).concat(val)

       this.list= [...new Set(this.list)];

      if(len1>len2){
         this.menu_list.forEach(param=>{
            param.menu_list.forEach(item1=>{
               if(item1.menu_id==aaa){
             this.bb.push({menu_btns_list:[],menu_id:item1.menu_parent_id})
            if(item1.menu_button_list){
              item1.menu_button_list.forEach(item=>{
              if(item){
                this.cc.push(item.menu_parent_id)
                this.cc= [...new Set(this.cc)];
              }

            })
            }
          }
            })

        })
         this.form.menu_list.push({menu_btns_list:this.cc,menu_id:aaa})
         this.bb.forEach((v)=>{

         let ss = this.form.menu_list.find(c=>c.menu_id==v.menu_id);
          if(!ss){

            this.form.menu_list=this.form.menu_list.concat(this.bb)
          }
         })

       this.form.menu_list.forEach(param1=>{
          param1.menu_btns_list.forEach(item1=>{
            if(item1){
 this.checkboxGroup3.push(item1)
            }
        })

        })
      }else{
        this.form.menu_list.forEach(param=>{
          if(param.menu_id==aaa){
            param.menu_btns_list=[]
          }
          param.menu_btns_list.forEach(item1=>{
          this.checkboxGroup3.push(item1)
        })
          this.list.forEach(item2=>{
            if(item2==param.menu_id){
              this.bb.push({menu_btns_list:param.menu_btns_list,menu_id:item2})
            }
          })
        })
        this.form.menu_list=this.bb

      }
      this.checkboxGroup4=val
      // this.form.menu_list=this.form.menu_list.concat(this.list)
     const id='menu_id'
this.form.menu_list=this.form.menu_list.reduce((all,next)=>all.some((item)=>item[id]==next[id])?all:[...all,next],[]);

   },
     handleCheckedChange1(val,val1){

         this.checkboxGroup1.push(val)
          this.checkboxGroup1= [...new Set(this.checkboxGroup1)];
        this.list1=[];
          this.aa=[];
      this.list1=this.list1.concat(val1)
       this.list1= [...new Set(this.list1)];
                this.menu_list.forEach(item=>{
                  item.menu_list.forEach(item1=>{
                 if(item1.menu_id==val){
                    this.form.menu_list.push({menu_btns_list:[],menu_id:item1.menu_id})
                     item1.menu_button_list.forEach(item2=>{
                     this.list1.forEach(item3=>{
                        if(item2.menubtn_id==item3){
                   this.aa.push(item3)
                   this.form.menu_list.forEach(item4=>{
                        if(item4.menu_id==val){
                          item4.menu_btns_list=[]
                          item4.menu_btns_list=this.aa
                        }
                   })
                        }
                     })
                   })



               }
                })
             })
        const id='menu_id'
this.form.menu_list=this.form.menu_list.reduce((all,next)=>all.some((item)=>item[id]==next[id])?all:[...all,next],[]);

    },
     submitForm(form){
          let paramas={
            role_name:this.form.role_name,
            role_id:this.form.role_id,
            company_id:this.form.company_id
          }
         this.$refs[form].validate((valid) => {
        if (valid) {
           axios.get("/pc/role/exist", paramas).then(v => {
          if(this.form.role_id){
             axios.put('/pc/role/update', this.form).then((v) => {
              this.$router.back()
              })
          }else{
             axios.post('/pc/role/save', this.form).then((v) => {
              this.$router.back()
              })
            }
          });
        }else{
           this.$message.error('请检查表单必填项');
            return false
        }
         })
      },
      handleChange(){}
  }
}
</script>
<style lang="scss" scoped>
.patroladd{
   overflow-x: hidden;
  .add{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .el-image{width: 160px; height: 160px;margin-right: 10px}
  .back_btn{background:rgba(247,248,249,1);border:1px solid rgba(217,222,225,1);}
  .patroladd-title{height: 34px;line-height: 34px;border-left: 2px solid #788287}
  .patroladd-title1{height: 34px;line-height: 34px;}
.el-checkbox{    display: flex;
    justify-content: left;
    align-items: center;}
/deep/ .el-collapse-item .el-collapse-item__content{padding-bottom: 0px;}
}

</style>
